<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Add Voter</div>
  </div>
  <div class="layout-content-section">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="aadhar-details-tab" data-bs-toggle="tab" data-bs-target="#aadharvoterdetail"
          type="button" role="tab" aria-controls="aadhar-tab-details" aria-selected="false"
          @click="redirectaddusingaadharurl()">
          Add using Aadhar
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="manual-entry-tab" data-bs-toggle="tab" data-bs-target="#voterreport"
          type="button" role="tab" aria-controls="customer-details" aria-selected="true">
          Add Manually
        </button>
      </li>
    </ul>
    <div class="voter-detail-box-outer">
      <div class="row">
        <div class="col-12">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" id="aadharvoterdetail" role="tabpanel" aria-labelledby="aadhar-details-tab">
              <div>Using Aadhar ID</div>
            </div>
            <div class="tab-pane fade show active" id="voterreport" role="tabpanel" aria-labelledby="manual-entry-tab">
              <div class="company-section-outer" style="height: calc(100vh - 196px); padding: 16px 16px 0;">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                          <div class="input-group custom-input-group">
                            <div class="col-lg-4 col-md-4 col-12">
                              <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                label="label" placeholder="Select" disabled
                                class="multiselect-custom custom-title-multiselect" :canClear="false"
                                :closeOnSelect="true" :object="true" noOptionsText="No Result" :canDeselect="false" />
                            </div>
                            <div class="col-lg-8 col-md-8 col-12">
                              <input type="text" v-model="searchmobile" :disabled="searchmobactivestatus || searchbtnloader"
                                class="form-control" id="formMobileNoInput" placeholder="Enter Mobile No." maxlength="10"
                                @keypress="onlyNumberMobile" @paste="onPasteMobile" autocomplete="off" />
                            </div>
                          </div>
                          <span class="custom-error" v-if="mobilealertmsg">
                            {{ mobilealertmsg }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group" style="margin-top: 26px;">
                          <label class="form-label"></label>
                          <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                            @click="searchvoterbtn(searchmobile)"
                            :disabled="searchmobile.length != 10 || searchmobactivestatus || searchbtnloader">
                            <span v-if="!searchbtnloader">Search</span>
                            <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="searchbtnloader">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 text-end" v-if="searchmobactivestatus">
                        <div class="custom-form-group" style="margin-top: 26px;">
                          <label class="form-label"></label>
                          <button type="button" class="btn custom-view-detail-btn" @click="clearsearchvoterform()">
                            Clear Form Data
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="searchdatafoundstatus">
                  <div class="col-lg-12 col-md-12 col-12 ">
                    <div class="row" v-for="(msg, index) in searchMobileResult" :key="msg">
                      <template v-if="index <= 4">
                        <div class="col-lg-12 col-md-12 col-12" v-if="msg.ak29 == 3">
                            <div class="voter-already-user-status">
                                <i class="pi pi-info-circle me-2"></i>+91 {{ msg.ak24 }} <span>this number has already registered as a User in the system.</span>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="msg.ak29 == 2">
                            <div class="voter-already-user-status">
                                <i class="pi pi-info-circle me-2"></i>+91 {{ msg.ak24 }} <span>this number has already registered as a Karyakartas in the system.</span>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="voter-exit-box-outer mb-3">
                            <div class="row">
                              <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                                <div class="customer-label-group">
                                  <div class="d-flex align-items-center">
                                    <div :class="msg.ak29 == 3
                                      ? 'border-voter-user' : msg.ak29 == 2 ? 'border-voter-karyakartas'
                                        : 'border-voter-only'
                                      ">
                                      <img
                                        v-if="(msg.ak90 == null || msg.ak90 == '') && (msg.ak33 == null || msg.ak33 == '')"
                                        src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                                      <img v-if="msg.ak90 != null && msg.ak90 != ''"
                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + msg.ak90"
                                        class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                      <img
                                        v-if="(msg.ak33 != null && msg.ak33 != '') && (msg.ak90 == null || msg.ak90 == 'NULL' || msg.ak90 == '')"
                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + msg.ak33"
                                        class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                      <img v-if="msg.ak29 == 1" src="/assets/images/icon/voters-tab-icon.svg" alt=""
                                        title="Voter" width="16" />
                                      <img v-if="msg.ak29 == 2" src="/assets/images/icon/users-tab-icon.svg" alt=""
                                        title="Karyakartas" width="16" />
                                      <img v-if="msg.ak29 == 3" src="/assets/images/icon/customers-tab-icon.svg" alt=""
                                        title="User" width="16" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-12">
                                <div class="row">
                                  <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label">Name</label>
                                      <div class="from-label-value text-capitalize">
                                        {{ msg.ak5 ? msg.ak5 : "N/A" }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label">Mobile No.</label>
                                      <div class="from-label-value">
                                        +91-{{ msg.ak24 }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label">Lok Sabha</label>
                                      <div class="from-label-value text-capitalize">
                                        {{ msg.ak63 ? msg.ak63 : "N/A" }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-3 col-12">
                                <div class="row">
                                  <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label">Email Id</label>
                                      <div class="from-label-value"
                                            v-if="this.allowshowemailvoterfk == 0">
                                            {{ msg.ak23 ? maskedEmail(msg.ak23) : "N/A" }}
                                        </div>
                                        <div class="from-label-value"
                                            v-if="this.allowshowemailvoterfk == 1">
                                            {{ msg.ak23 ? msg.ak23 : "N/A" }}
                                        </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label">Birth Date</label>
                                      <div class="from-label-value text-capitalize">
                                        {{ format_date(msg.ak18) }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label">Vidhan Sabha</label>
                                      <div class="from-label-value text-capitalize">
                                        {{ msg.ak61 ? msg.ak61 : "N/A" }}<span class="ms-1" v-if="msg.ab23">({{ msg.ab23 }})</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-2 col-md-2 col-12">
                                <div class="row">
                                  <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label">Gender</label>
                                      <div class="from-label-value text-capitalize">
                                        {{
                                          msg.ak22
                                          ? msg.ak22 == 1
                                            ? "Male"
                                            : msg.ak22 == 2
                                              ? "Female"
                                              : "Others"
                                          : "N/A"
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-12">
                                    <div class="customer-label-group mb-2">
                                      <label class="form-label">Designation</label>
                                      <div class="from-label-value text-capitalize">
                                        {{ msg.ak84 ? msg.ak84 : "N/A" }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-1 col-md-1 col-12" v-if="msg.ak29 == 1 && this.alloweditvoterfk == 1">
                                <div class="row">
                                  <div class="col-lg-12 col-md-12 col-12 text-end">
                                    <button type="button" title="Edit Voter Details" class="btn custom-outline-view-btn"
                                      @click="voterDetailEditModalOpen(msg)">
                                      <i class="pi pi-pencil call-btn-color"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12 text-end mb-3">
                    <button type="button" class="btn custom-view-detail-btn" :disabled="addvoterlimitset >= 6"
                      @click="addNewExitMobVoterModalOpen()">
                      Add New Voter
                    </button>
                  </div>
                </div>
                <div class="row" v-if="voternotexiststatus">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label for="formTitleInput" class="form-label">First Name <span
                              class="text-danger">*</span></label>
                          <div class="input-group custom-input-group">
                            <div class="col-lg-4 col-md-4 col-12">
                              <Multiselect v-model="add.title" :disabled="showloader" :options="titleList" :searchable="false" label="label"
                                placeholder="Title" class="multiselect-custom custom-title-multiselect" :canClear="false"
                                :closeOnSelect="true" :object="true" noOptionsText="No Result" :canDeselect="false"
                                @select="handleAddGender" />
                            </div>
                            <div class="col-lg-8 col-md-8 col-12">
                              <input type="text" v-model="add.firstname" :disabled="showloader" class="form-control text-capitalize"
                                id="formFirstNameInput" placeholder="Enter First Name" autocomplete="off" maxlength="20"
                                @keypress="isLetterWithOutSpace($event)" />
                            </div>
                          </div>
                          <div class="custom-error" v-if="v$.add.title.$error">
                            {{ v$.add.title.$errors[0].$message }}
                          </div>
                          <div class="custom-error" v-if="add.title != '' && v$.add.firstname.$error">
                            {{ v$.add.firstname.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label for="formMiddleNameInput" class="form-label">Middle Name</label>
                          <input type="text" v-model="add.middlename" :disabled="showloader" class="form-control text-capitalize"
                            id="formMiddleNameInput" placeholder="Enter Middle Name" autocomplete="off" maxlength="20"
                            @keypress="isLetterWithOutSpace($event)" />
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label for="formLasInput" class="form-label">Last Name<span
                              class="text-danger">*</span></label>
                          <input type="text" v-model="add.lastname" :disabled="showloader" class="form-control text-capitalize"
                            id="formLasInput" placeholder="Enter Last Name" autocomplete="off" maxlength="20"
                            @keypress="isLetterWithOutSpace($event)" />
                          <div class="custom-error" v-if="v$.add.lastname.$error">
                            {{ v$.add.lastname.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label for="formEmailInput" class="form-label">Email Id</label>
                          <input type="text" v-model="add.email" :disabled="showloader" class="form-control" id="formEmailInput"
                            placeholder="Enter Email Id" autocomplete="off" maxlength="50" />
                          <div class="custom-error" v-if="v$.add.email.$error">
                            {{ v$.add.email.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                     
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Gender<span class="text-danger">*</span></label>
                          <Multiselect v-model="add.gender" :disabled="showloader" :options="genderList" :searchable="true" label="label"
                            placeholder="Select" class="multiselect-custom" :canClear="false" :closeOnSelect="true"
                            :object="true" noOptionsText="No Result" />
                          <div class="custom-error" v-if="v$.add.gender.$error">
                            {{ v$.add.gender.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label for="formBirthDateInput" class="form-label">Birth Date<span
                              class="text-danger">*</span></label>
                          <Calendar v-model="add.birthdate" :disabled="showloader" :minDate="minDateBirth" :maxDate="maxDateBirth"
                            :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            :manualInput="false" :monthNavigator="true" :yearRange="'1950:' + maxDateBirth.getFullYear()"
                            appendTo="body" :yearNavigator="true">
                          </Calendar>
                          <div class="custom-error" v-if="v$.add.birthdate.$error">
                            {{ v$.add.birthdate.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Blood Group</label>
                          <Multiselect v-model="add.bloodgroup" :disabled="showloader" :options="bloodgroupList" :searchable="true" label="label"
                            placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                            :object="true" noOptionsText="No Result" />
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12" v-show="this.allowaddvoterprofessionalinfo != 0">
                        <div class="custom-form-group">
                          <label class="form-label">Profession</label>
                          <Multiselect v-model="add.profession" :disabled="showloader" :options="professionList" :searchable="true" label="label"
                            placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                            :object="true" noOptionsText="No Result" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Lok Sabha Name<span
                              class="text-danger">*</span></label>
                          <Multiselect v-model="add.loksabha" :disabled="showloader" :options="loksabhaList" :searchable="true"
                            @change="getvidhansabhaName($event)" label="label" placeholder="Select"
                            class="multiselect-custom" :canClear="true" :closeOnSelect="true" :object="true"
                            noOptionsText="No Result" />
                          <div class="custom-error" v-if="v$.add.loksabha.$error">
                            {{ v$.add.loksabha.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Vidhan Sabha Name<span
                              class="text-danger">*</span></label>
                          <Multiselect v-show="!vidhanloaderflag" :disabled="showloader" v-model="add.vidhansabha" :options="vidhansabhaList"
                            :searchable="true" label="label" placeholder="Select" class="multiselect-custom"
                            :canClear="true" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                          <Multiselect v-show="vidhanloaderflag" loading :searchable="false" label="label"
                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                          <div class="custom-error" v-if="v$.add.vidhansabha.$error">
                            {{ v$.add.vidhansabha.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12" v-show="!isCompanyNameExistAdd && this.allowaddvoterprofessionalinfo != 0">
                          <div class="custom-form-group">
                              <label class="form-label">Company / Business Name</label>
                              <Multiselect v-model="add.companyname" :options="companyList" :disabled="showloader" :searchable="true" label="label"
                                      placeholder="Search Company Name" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                                      :object="true" noOptionsText="No Result Found" 
                              :createOption="false"  />
                              <label style="line-height: 14px;">
                                  Not found?,
                                  <span>
                                      <a class="p-ai-center p-text-right cursor-pointer"
                                          style="color: #3f51b5" @click="toggleCompanyNameAdd">Click
                                          here to add</a>
                                  </span>
                              </label>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12" v-show="isCompanyNameExistAdd && this.allowaddvoterprofessionalinfo != 0">
                          <div class="custom-form-group">
                              <label for="createcom" class="form-label">Add Company / Business Name</label>
                              <input type="text" class="form-control" :disabled="showloader" @input="createOtherCompanyNameAdd"
                              placeholder="Enter Company / Business Name" autocomplete="off" maxlength="30" />
                              <label style="line-height: 14px;">
                                  For search,
                                  <span>
                                      <a class="p-ai-center p-text-right cursor-pointer"
                                          style="color: #3f51b5" @click="toggleCompanyNameAdd">Click
                                          here</a>
                                  </span>
                              </label>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12" v-show="!isDesignationExistAdd && this.allowaddvoterprofessionalinfo != 0">
                          <div class="custom-form-group">
                              <label class="form-label">Designation</label>
                              <Multiselect v-model="add.designation" :disabled="showloader" :options="clientdesignationList" :searchable="true"
                              label="label" placeholder="Search Designation" class="multiselect-custom text-capitalize"
                              :canClear="true" :closeOnSelect="true" noOptionsText="No Result Found" :object="true"
                              :createOption="false" />
                              <label style="line-height: 14px;">
                                  Not found?,
                                  <span>
                                      <a class="p-ai-center p-text-right cursor-pointer"
                                          style="color: #3f51b5" @click="toggleDesginationNameAdd">Click
                                          here to add</a>
                                  </span>
                              </label>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12" v-show="isDesignationExistAdd && this.allowaddvoterprofessionalinfo != 0">
                          <div class="custom-form-group">
                              <label for="createdesingation" class="form-label">Add Designation</label>
                              <input type="text" class="form-control" :disabled="showloader" @input="createOtherDesignationNameAdd"
                              placeholder="Enter Designation Name" autocomplete="off" maxlength="30" />
                              <label style="line-height: 14px;">
                                  For search,
                                  <span>
                                      <a class="p-ai-center p-text-right cursor-pointer"
                                          style="color: #3f51b5" @click="toggleDesginationNameAdd">Click
                                          here</a>
                                  </span>
                              </label>
                          </div>
                      </div>
                    </div>
                    <div class="row">
                     <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Select City<span class="text-danger">*</span></label>
                          <Multiselect v-show="!cityloaderflag" :disabled="showloader" v-model="add.city" :options="cityList" :searchable="true"
                            label="label" placeholder="Select" class="multiselect-custom"
                            @keypress="isLetterWithSpace($event)" :canClear="true" :closeOnSelect="true"
                            noOptionsText="No Result" :object="true" @select="getAreaByCityId(add.city)" required />
                          <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label"
                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                          <div class="custom-error" v-if="v$.add.city.$error">
                            {{ v$.add.city.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Select Area<span v-if="areaList !=null" class="text-danger">*</span></label>
                          <Multiselect v-show="!arealoaderflag" :disabled="showloader" v-model="add.area" :options="areaList" :searchable="true"
                            label="label" placeholder="Select" class="multiselect-custom"
                            @keypress="isLetterWithSpace($event)" :canClear="true" :closeOnSelect="true"
                            noOptionsText="No Result" :object="true" required />
                          <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label"
                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                            <div class="custom-error" v-if="v$.add.area.$error">
                              {{ v$.add.area.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Pincode<span class="text-danger">*</span></label>
                          <input type="text" v-model="add.pincode" :disabled="showloader" id="addpincodeInput" class="form-control text-capitalize"
                            placeholder="Enter Pincode" maxlength="6" @paste="onPasteMobile" @keypress="onlypincodeAddFormat" autocomplete="off" />
                          <div class="custom-error" v-if="v$.add.pincode.$error">
                            {{ v$.add.pincode.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label for="formLandmarkInput" class="form-label">Nearest Landmark</label>
                          <input type="text" v-model="add.landmark" :disabled="showloader" class="form-control text-capitalize"
                            id="formLandmarkInput" placeholder="Enter Landmark" autocomplete="off" maxlength="50" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="custom-form-group">
                          <label for="formShortNameInput" class="form-label">Current Address<span
                              class="text-danger">*</span></label>
                          <textarea type="text" v-model="add.fulladdress" :disabled="showloader" class="form-control custom-text-area-scroll"
                            placeholder="Enter Full Address" autocomplete="off" rows="1" maxlength="250"></textarea>
                          <div class="custom-error" v-if="v$.add.fulladdress.$error">
                            {{ v$.add.fulladdress.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                              <label class="form-label">Upload Photo</label>
                              <FileUpload mode="basic" :disabled="showloader" accept="image/*" ref="votercreateimg" class="custom-upload-btn-ultima"
                                v-model="add.votercreateimg" :auto="true" v-on:change="handleUploadVoterCreate()" />
                              <div class="custom-error" v-if="errorprocreateimage">{{ errorprocreateimage }}</div>

                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12" v-if="createpreviewurl != null && createpreviewurl != ''">
                            <img :src="createpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    m-start
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                          </div>
                          <div class="text-primary" v-if="createpreviewurl != null">{{ createpreviewurl.name }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                          <div class="custom-form-group">
                              <label class="form-label d-flex justify-content-between"><span>Select
                                      Tags</span><small class="muted-text text-primary">(Max
                                      5)</small></label>
                              <Multiselect v-model="add.tagname" :disabled="showloader" :options="taggroupList" mode="tags"
                                  :createTag="false" label="label"
                                  class="multiselect-custom form-custom-select text-capitalize"
                                  placeholder="Select" :closeOnSelect="true" :searchable="true" :object="true"
                                  :max="5" />
                          </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Assign Voter to</label>
                          <Multiselect v-model="add.voterowner" :disabled="showloader" :options="voterownerList" :searchable="true" label="label"
                            placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                            :closeOnSelect="true" :object="true" noOptionsText="No Result" appendTo="body" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="custom-form-group text-center">
                          <button type="button" @click="createNewVoter()" class="btn modal-bulk-next-btn"
                            :disabled="showloader"> 
                            <span v-if="!showloader">Add</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                              v-if="showloader">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Edit Voter Detail start here -->
  <div class="modal-mask" v-if="editvoterdetailstatus">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Edit Voter Details</h5>
          <button type="button" class="btn-close" @click="voterDetailEditModalClose()"></button>
        </div>
        <div class="modal-body modal-body-scroll-outer">
          <div style="position: relative;
    min-height: 443px;">
            <div v-if="!showloadervotermodal">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                    <div class="input-group custom-input-group">
                      <div class="col-lg-4 col-md-4 col-12">
                        <Multiselect v-model="edit.countrycode" :options="countryCodeList" :searchable="true"
                          label="label" placeholder="Select" disabled class="multiselect-custom custom-title-multiselect"
                          :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                          :canDeselect="false" />
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <input type="text" v-model="edit.primobile" id="editmobileInput" class="form-control" disabled
                          placeholder="Enter Mobile No." maxlength="10" @paste="onPasteMobile" @keypress="onlymobileEditFormat" autocomplete="off" />
                      </div>
                    </div>
                    <div class="custom-error" v-if="v$.edit.primobile.$error">
                      {{ v$.edit.primobile.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formFirstNameInput" class="form-label">First Name<span class="text-danger">*</span></label>
                    <div class="input-group custom-input-group">
                      <div class="col-lg-4 col-md-4 col-12">
                        <Multiselect v-model="edit.title" :disabled="editvoterbtnloader" :options="titleList" :searchable="false" label="label"
                          placeholder="Select" class="multiselect-custom custom-title-multiselect text-capitalize"
                          :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                          :canDeselect="false" @select="handleEditGender" />
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <input type="text" v-model="edit.firstname" :disabled="editvoterbtnloader" class="form-control text-capitalize"
                          id="formFirstNameInput" placeholder="Enter First Name" autocomplete="off" maxlength="20"
                          @keypress="isLetterWithOutSpace($event)" />
                      </div>
                    </div>
                    <div class="custom-error" v-if="v$.edit.title.$error">
                      {{ v$.edit.title.$errors[0].$message }}
                    </div>
                    <div class="custom-error" v-if="edit.title != '' && v$.edit.firstname.$error">
                      {{ v$.edit.firstname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formMNameInput" class="form-label">Middle Name</label>
                    <input type="text" v-model="edit.middlename" :disabled="editvoterbtnloader" class="form-control text-capitalize"
                      id="formMNameInput" placeholder="Enter Middle Name" autocomplete="off" maxlength="20"
                      @keypress="isLetterWithOutSpace($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLastNameInput" class="form-label">Last Name<span class="text-danger">*</span></label>
                    <input type="text" v-model="edit.lastname" :disabled="editvoterbtnloader" class="form-control text-capitalize" id="formLastNameInput"
                      placeholder="Enter Last Name" autocomplete="off" maxlength="20"
                      @keypress="isLetterWithOutSpace($event)" />
                    <div class="custom-error" v-if="v$.edit.lastname.$error">
                      {{ v$.edit.lastname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formEmailInput" class="form-label">Email Id</label>
                    <input type="text" v-model="edit.email" :disabled="editvoterbtnloader" class="form-control" id="formEmailInput"
                      placeholder="Enter Email Id" autocomplete="off" maxlength="50" />
                    <div class="custom-error" v-if="v$.edit.email.$error">
                      {{ v$.edit.email.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formGenderInput" class="form-label">Gender<span class="text-danger">*</span></label>
                    <Multiselect v-model="edit.gender" :disabled="editvoterbtnloader" :options="genderList" :searchable="false" id="formGenderInput" label="label"
                      placeholder="Select" class="multiselect-custom" :canClear="false" :closeOnSelect="true"
                      :object="true" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.gender.$error">
                      {{ v$.edit.gender.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formBirthInput" class="form-label">Birth Date<span class="text-danger">*</span></label>
                    <Calendar v-model="edit.birthdate" :disabled="editvoterbtnloader" id="formBirthInput" :maxDate="maxDateBirth" :showIcon="true"
                      class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                      :monthNavigator="true" :yearRange="'1950:' + maxDateBirth.getFullYear()"
                      appendTo="body" :yearNavigator="true">
                    </Calendar>
                    <div class="custom-error" v-if="v$.edit.birthdate.$error">
                      {{ v$.edit.birthdate.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLokSabhaInput" class="form-label">Lok Sabha Name<span class="text-danger">*</span></label>
                    <Multiselect v-model="edit.loksabha" :disabled="editvoterbtnloader" :options="loksabhaList" :searchable="true"
                      @change="getvidhansabhaName($event)" @keypress="isLetterWithSpace($event)" label="label"
                      placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                      :object="true" noOptionsText="No Result" id="formLokSabhaInput" required />
                    <div class="custom-error" v-if="v$.edit.loksabha.$error">
                      {{ v$.edit.loksabha.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Vidhan Sabha Name<span class="text-danger">*</span></label>
                    <Multiselect v-show="!vidhanloaderflag" :disabled="editvoterbtnloader" v-model="edit.vidhansabha" :options="vidhansabhaList"
                      :searchable="true" label="label" placeholder="Select" @keypress="isLetterWithSpace($event)"
                      class="multiselect-custom" :canClear="false" :closeOnSelect="true" :object="true"
                      noOptionsText="No Result" />
                    <Multiselect v-show="vidhanloaderflag" loading :searchable="true" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.vidhansabha.$error">
                      {{ v$.edit.vidhansabha.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formBloodInput" class="form-label">Blood Group</label>
                    <Multiselect v-model="edit.bloodgroup" :disabled="editvoterbtnloader" :options="bloodgroupList" :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                      :object="true" noOptionsText="No Result" id="formBloodInput" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formProfessionInput" class="form-label">Profession</label>
                    <Multiselect v-model="edit.profession" :disabled="editvoterbtnloader || this.allowmodifyvoterprofessionalinfo == 0" :options="professionList" :searchable="true" label="label"
                      placeholder="Select" id="formProfessionInput" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                      :object="true" noOptionsText="No Result" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="!isCompanyNameExist">
                  <div class="custom-form-group">
                    <label class="form-label">Company / Business Name</label>
                    <Multiselect v-model="edit.companyname" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" :options="companyList" :searchable="true" label="label"
                            placeholder="Search Company Name" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                            :object="true" noOptionsText="No Result Found" 
                      :createOption="false"  />
                      <label style="line-height: 14px;">
                          Not found?,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleCompanyName">Click
                                  here for add</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="isCompanyNameExist">
                  <div class="custom-form-group">
                    <label for="createcom" class="form-label">Add Company / Business Name</label>
                    <input type="text" class="form-control" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" @input="createOtherCompanyName"
                      placeholder="Enter Company / Business Name" autocomplete="off" maxlength="30" />
                      <label style="line-height: 14px;">
                          For search,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleCompanyName">Click
                                  here</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="!isDesignationExist">
                  <div class="custom-form-group">
                    <label class="form-label">Designation</label>
                    <Multiselect v-model="edit.designation" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" :options="clientdesignationList" :searchable="true"
                      label="label" placeholder="Search Designation" class="multiselect-custom text-capitalize"
                      :canClear="true" :closeOnSelect="true" noOptionsText="No Result Found" :object="true"
                      :createOption="false" />
                      <label style="line-height: 14px;">
                          Not found?,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleDesginationName">Click
                                  here for add</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="isDesignationExist">
                  <div class="custom-form-group">
                    <label for="createdesingation" class="form-label">Add Designation</label>
                    <input type="text" class="form-control" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" @input="createOtherDesignationName"
                      placeholder="Enter Designation Name" autocomplete="off" maxlength="30" />
                      <label style="line-height: 14px;">
                          For search,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleDesginationName">Click
                                  here</a>
                          </span>
                      </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select City<span class="text-danger">*</span></label>
                    <Multiselect v-show="!cityloaderflag" :disabled="editvoterbtnloader" v-model="edit.city" :options="cityList" :searchable="true"
                      label="label" placeholder="Select" class="multiselect-custom" @keypress="isLetterWithSpace($event)"
                      :canClear="true" :closeOnSelect="true" noOptionsText="No Result" :object="true"
                      @select="getAreaByCityId(edit.city)" required />
                    <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.city.$error">
                      {{ v$.edit.city.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select Area<span v-if="areaList !=null" class="text-danger">*</span></label>
                    <Multiselect v-show="!arealoaderflag" :disabled="editvoterbtnloader" v-model="edit.area" :options="areaList" :searchable="true"
                      label="label" placeholder="Select" class="multiselect-custom" @keypress="isLetterWithSpace($event)"
                      :canClear="true" :closeOnSelect="true" noOptionsText="No Result" :object="true" required />
                    <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                      <div class="custom-error" v-if="v$.edit.area.$error">
                          {{ v$.edit.area.$errors[0].$message }}
                      </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Pincode<span class="text-danger">*</span></label>
                    <input type="text" v-model="edit.pincode" :disabled="editvoterbtnloader" id="editpincodeInput" class="form-control text-capitalize"
                      placeholder="Enter Pincode" maxlength="6" @keypress="onlypincodeEditFormat" autocomplete="off" />
                    <div class="custom-error" v-if="v$.edit.pincode.$error">
                      {{ v$.edit.pincode.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLandmarkInput" class="form-label">Nearest Landmark</label>
                    <input type="text" v-model="edit.landmark" :disabled="editvoterbtnloader" class="form-control text-capitalize" id="formLandmarkInput"
                      placeholder="Enter Landmark" autocomplete="off" maxlength="50" />
                  </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <div class="custom-form-group">
                    <label for="formAddressInput" class="form-label">Current Address<span
                        class="text-danger">*</span></label>
                    <textarea type="text" v-model="edit.fulladdress" :disabled="editvoterbtnloader" class="form-control custom-text-area-scroll"
                      placeholder="Enter Full Address" autocomplete="off" rows="1" maxlength="250" id="formAddressInput"></textarea>
                    <div class="custom-error" v-if="v$.edit.fulladdress.$error">
                      {{ v$.edit.fulladdress.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label class="form-label d-flex justify-content-between"><span>Select Tags</span><small class="muted-text text-primary">(Max
                        5)</small></label>
                    <Multiselect v-model="edit.tagname" :disabled="editvoterbtnloader" :options="taggroupList" mode="tags" :createTag="false" label="label" :class="this.allowtagremoveaccess == 0 ? 'tagdisabled' : ''" class="multiselect-custom form-custom-select text-capitalize"
                    placeholder="Select" :closeOnSelect="true" :canClear="false" :searchable="false" :object="true" :max="5" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Upload Photo</label>
                    <FileUpload mode="basic" accept="image/*" :disabled="editvoterbtnloader" :auto="true" ref="voterprofileimg" class="custom-upload-btn-ultima"
                      v-model="edit.voterprofileimg" v-on:change="handleUploadVoterProfile()" />
                    <div class="custom-error" v-if="errorprofileimage">{{ errorprofileimage }}</div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl == null">
                  <img v-if="edit.importprofilefile != null && edit.importprofilefile != ''" :src="edit.importprofilefile"
                    class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                " alt="" />
                  <img src="/assets/images/profile.svg" v-if="edit.importprofilefile == null" class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                " alt="" />
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl != null">
                  <img :src="exitpreviewurl.objectURL" class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                  voter-photo-img
                                " alt="" />
                </div>
                <div class="text-primary" v-if="exitpreviewurl != null">{{ exitpreviewurl.name }}</div>
              </div>
            </div>
            <div v-if="showloadervotermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateVoterDetailbtn()" :disabled="editvoterbtnloader">
              <span v-if="!editvoterbtnloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="editvoterbtnloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Edit Voter Detail end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import moment from "moment";
import { required, helpers, email, numeric, maxLength,requiredIf, minLength } from '@vuelidate/validators';
export default {
  data() {
    return {
      v$: useValidate(),
      errormsg: "",
      add: {
        title: '',
        firstname: "",
        middlename: "",
        lastname: "",
        primobile: "",
        email: "",
        gender: "",
        votertype: 1,
        birthdate: new Date(new Date().setFullYear(new Date().getFullYear() - 30)),
        loksabha: '',
        vidhansabha: '',
        designation: "",
        companyname: "",
        votercreateimg: '',
        fulladdress: '',
        city: "",
        area: "",
        pincode: '',
        landmark: '',
        voterowner: '',
        bloodgroup: '',
        profession: '',
        tagname: [],
      },
      editRowId: '',
      edit: {
        birthdate: null,
        loksabha: '',
        vidhansabha: '',
        companyname: '',
        designation: '',
        importprofilefile: '',
        voterprofileimg: '',
        title: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        gender: '',
        primobile: "",
        countrycode: { value: 101, label: "+91" },
        fulladdress: '',
        city: "",
        area: "",
        pincode: '',
        landmark: '',
        bloodgroup: '',
        profession: '',
        tagname: [],
      },
      countrycode: { value: 101, label: "+91" },
      titleList: [],
      countryCodeList: [],
      professionList: [
        { label: "Student", value: 1 },
        { label: "Self Employed", value: 2 },
        { label: "Salaried", value: 3 },
        { label: "Business", value: 4 },
        { label: "Farmer", value: 5 },
        { label: "Retired", value: 6 },
        { label: "Housewife", value: 7 },
      ],
      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Other", value: 3 },
      ],
      bloodgroupList: [],
      loksabhaList: [],
      vidhansabhaList: [],
      clientdesignationList: [],
      showloader: false,
      submitted: false,
      maxDateBirth: moment().subtract(1, "years")._d,
      minDateBirth: moment().subtract(120, "years")._d,
      errorprofileimage: '',
      cityList: [],
      areaList: [],
      vidhanloaderflag: false,
      cityloaderflag: false,
      arealoaderflag: false,
      createpreviewurl: null,
      voterownerList: [],
      searchmobile: '',
      searchbtnloader: false,
      searchMobileResult: [],
      client_info: '',
      voterstorageimgpath: '',
      voternotexiststatus: false,
      alloweditvoterfk: '',
      user_info: '',
      searchmobactivestatus: false,
      searchdatafoundstatus: false,
      editvoterbtnloader: false,
      errorprocreateimage: '',
      editvoterdetailstatus: false,
      showloadervotermodal: false,
      exitpreviewurl: null,
      limitationList: 2,
      loginuserdeafultid: null,
      addvoterlimitset: '',
      taggroupList: [],
      companyList: [],
      mobilealertmsg: '',
      isCompanyNameExist: false,
      isDesignationExist: false,
      isCompanyNameExistAdd: false,
      isDesignationExistAdd: false,
      allowshowmobilevoterfk: '',
      allowshowemailvoterfk: '',
      allowshowaadharvoterfk: '',
      allowaddvoterprofessionalinfo: 0,
      allowmodifyvoterprofessionalinfo: 0,
      allowtagadditionaccess: '',
      allowtagremoveaccess: '',
      allowuseraddmanually: '',
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.loading = true;
    this.getalltitle();
    this.getloksabha();
    this.getclientdesignations();
    this.getactiveusers();
    this.getcities();
    this.getbloodgroups();
    this.gettags();
    this.getcompanylist();
    this.client_info = JSON.parse(localStorage.client_info);
    this.voterstorageimgpath = this.client_info.maa24;
    this.user_info = JSON.parse(localStorage.user);
    this.alloweditvoterfk = this.user_info.ak109;
    this.loginuserdeafultid = this.user_info.user_id;
    this.allowshowmobilevoterfk = this.user_info.ak105;
    this.allowshowemailvoterfk = this.user_info.ak106;
    this.allowshowaadharvoterfk = this.user_info.ak107;
    this.allowaddvoterprofessionalinfo = this.user_info.ak199;
    this.allowmodifyvoterprofessionalinfo = this.user_info.ak200;
    this.allowtagadditionaccess = this.user_info.ak197;
    this.allowtagremoveaccess = this.user_info.ak198;
  },
  validations() {
    return {
      add: {
        title: { required: helpers.withMessage('Please select title', required) },
        firstname: { required: helpers.withMessage('Please enter first name', required) },
        lastname: { required: helpers.withMessage('Please enter last name', required) },
        email: { email: helpers.withMessage('Email address is invalid', email) },
        birthdate: { required: helpers.withMessage('Please select birth date', required) },
        gender: { required: helpers.withMessage('Please select gender', required) },
        loksabha: { required: helpers.withMessage('Please select lok sabha', required) },
        vidhansabha: { required: helpers.withMessage('Please select vidhan sabha', required) },
        fulladdress: { required: helpers.withMessage('Please enter full address', required) },
        city: { required: helpers.withMessage('Please select city', required) },
        // area: { required: helpers.withMessage('Please select area', required) },
        pincode: {
          required: helpers.withMessage("Please enter pincode", required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        area: {
          requiredIf: helpers.withMessage(
            "Please select area",
            requiredIf(
              this.areaList != null
            )
          ),
        },
      },
      edit: {
        primobile: {
          required: helpers.withMessage('Please enter Mobile No.', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: helpers.withMessage(
            "Mobile no should be min 10 digit",
            minLength(10)
          ), maxLength: maxLength(10)
        },
        title: { required: helpers.withMessage('Please select title', required) },
        firstname: {
          required: helpers.withMessage("Please enter first name", required),
        },
        lastname: {
          required: helpers.withMessage("Please enter last name", required),
        },
        email: {
          email: helpers.withMessage('Email address is invalid', email)
        },
        birthdate: { required: helpers.withMessage('Please select birth date', required) },
        gender: { required: helpers.withMessage('Please select gender', required) },
        loksabha: { required: helpers.withMessage("Please select lok sabha", required) },
        vidhansabha: { required: helpers.withMessage('Please select vidhan sabha', required) },
        fulladdress: { required: helpers.withMessage('Please enter full address', required) },
        city: { required: helpers.withMessage('Please select city', required) },
        // area: { required: helpers.withMessage('Please select area', required) },
        pincode: {
          required: helpers.withMessage('Please enter pincode', required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        area: {
          requiredIf: helpers.withMessage(
            "Please select area",
            requiredIf(
              this.areaList != null
            )
          ),
        },
      },
    };
  },
  methods: {
    createOtherCompanyName(e) {
        this.edit.companyname = { value: 0, label: e.target.value };
    },
    toggleCompanyName() {
        this.isCompanyNameExist = !this.isCompanyNameExist;
        this.edit.companyname = '';
    },
    createOtherDesignationName(e) {
        this.edit.designation = { value: 0, label: e.target.value };
    },
    toggleDesginationName() {
        this.isDesignationExist = !this.isDesignationExist;
        this.edit.designation = '';
    },
    
    createOtherCompanyNameAdd(e) {
        this.add.companyname = { value: 0, label: e.target.value };
    },
    toggleCompanyNameAdd() {
        this.isCompanyNameExistAdd = !this.isCompanyNameExistAdd;
        this.add.companyname = '';
    },
    createOtherDesignationNameAdd(e) {
        this.add.designation = { value: 0, label: e.target.value };
    },
    toggleDesginationNameAdd() {
        this.isDesignationExistAdd = !this.isDesignationExistAdd;
        this.add.designation = '';
    },
    getcompanylist(e) {
      this.ApiService.getcompanylist(e).then((data) => {
        if (data.status == 200) {
          this.companyList = data.data;
        } else {
          this.companyList = null;
        }
      });
    },
    searchvoterbtn(mobilenumber) {
      this.searchbtnloader = true;
      this.ak24 = mobilenumber;
      this.add.primobile = this.ak24;
      this.ApiService.checkMobileExists({ ak24: this.ak24 }).then((data) => {
        if (data.status == 200) {
          this.searchMobileResult = data.data;
          this.addvoterlimitset = data.count;
          this.searchbtnloader = false;
          this.searchdatafoundstatus = true;
          this.searchmobactivestatus = true;
          this.mobilealertmsg = '';
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
        } else {
          if (data.status == 422) {
            this.mobilealertmsg = data.message;
            this.searchbtnloader = false;
            this.searchmobactivestatus = false ;
          } 
          else {
            this.mobilealertmsg = '';
            this.searchMobileResult = null;
            this.usersearchalertmodals = true;
            this.searchbtnloader = false;
            this.voternotexiststatus = true;
            this.searchdatafoundstatus = false;
            this.searchmobactivestatus = true;
            this.isCompanyNameExistAdd = false;
      this.isDesignationExistAdd = false;
          }
        }
      });
    },
    getactiveusers(e) {
      this.ApiService.getactiveusers(e).then((data) => {
        if (data.status == 200) {
          this.voterownerList = data.data;
          if (this.loginuserdeafultid != null) {
            if (this.voterownerList.length >= 1) {
              let datafk = this.loginuserdeafultid;
              let vals = this.voterownerList.filter(function (item) {
                return item.value == datafk;
              });
              this.add.voterowner = { value: vals[0].value, label: vals[0].label };
            }
          }
        }else{
          this.voterownerList = null;
        }
      });
    },
    redirectaddusingepicurl() {
      this.$router.push("/voters/addusingepic");
    },
    redirectaddusingaadharurl() {
      this.$router.push("/voters/addusingaadhar");
    },
    getcountrycode(e) {
      this.ApiService.getcountrycode(e).then((data) => {
        if (data.status == 200) {
          this.countryCodeList = data.data;
        }
      });
    },
    getalltitle(e) {
      this.ApiService.getalltitle(e).then((data) => {
        if (data.status == 200) {
          this.titleList = data.data;
        }
      });
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput =  document.getElementById("formMobileNoInput");
      if((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)){
        $event.preventDefault();
      }
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onlypincodeAddFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var addpincodeInput = document.getElementById("addpincodeInput");
      if ((addpincodeInput.value == '' || addpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    handleEditGender() {
      if (this.edit.title.value == 1) {
        this.edit.gender = {
          label: "Male",
          value: 1,
        }
      } else if (this.edit.title.value == 2 || this.edit.title.value == 3) {
        this.edit.gender = {
          label: "Female",
          value: 2,
        }
      } else {
        this.edit.gender = '';
      }
    },
    handleAddGender() {
      if (this.add.title.value == 1) {
        this.add.gender = {
          label: "Male",
          value: 1,
        }
      } else if (this.add.title.value == 2 || this.add.title.value == 3) {
        this.add.gender = {
          label: "Female",
          value: 2,
        }
      } else {
        this.add.gender = '';
      }
    },
    handleUploadVoterCreate() {
      var file = this.$refs.votercreateimg.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorprocreateimage = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorprocreateimage = 'Invalid file type';
      } else {
        this.errorprocreateimage = '';
        this.add.votercreateimg = this.$refs.votercreateimg.files[0];
        this.createpreviewurl = this.add.votercreateimg;
      }
    },
    process(input){
      let value = input.value;
      let numbers = value.replace(/[^0-9]/g, "");
      input.value = numbers;
    },
    handleUploadVoterProfile() {
      var file = this.$refs.voterprofileimg.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorprofileimage = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorprofileimage = 'Invalid file type';
      } else {
        this.errorprofileimage = '';
        this.edit.voterprofileimg = this.$refs.voterprofileimg.files[0];
        this.exitpreviewurl = this.edit.voterprofileimg;
      }
    },
    getloksabha(e) {
      this.add.vidhansabha = '';
      this.ApiService.getloksabha(e).then((data) => {
        if (data.status == 200) {
          this.loksabhaList = data.data;
          if(this.loksabhaList.length == 1){
            this.add.loksabha = {
              label: this.loksabhaList[0].label,
              value: this.loksabhaList[0].value,
            };
          }
        }
      });
      
    },
    getvidhansabhaName(e) {
      if(e != null){
        this.vidhanloaderflag = true;
        this.ApiService.getvidhansabha({ lokhsabha_id: e.value }).then((items) => {
          if (items.success === true) {
            this.vidhansabhaList = items.data;
            this.vidhanloaderflag = false;
          } else {
            this.vidhanloaderflag = false;
            this.vidhansabhaList = [];
          }
        });
      }else{
        this.vidhansabhaList = [];
      }
    },
    getclientdesignations(e) {
      this.ApiService.getclientdesignations(e).then((data) => {
        if (data.status == 200) {
          this.clientdesignationList = data.data;
        }
      });
    },
    getcities(e) {
      this.cityloaderflag = true;
      this.add.city = '';
      this.add.area = '';
      this.ApiService.getcities(e).then((data) => {
        if (data.success == true) {
          this.cityList = data.data;
          this.cityloaderflag = false;
          this.areaList = null;
        } else {
          this.cityloaderflag = false;
          this.cityList = [];
          this.areaList = null;
        }
      });
    },
    getAreaByCityId(e) {
      this.arealoaderflag = true;
      this.add.area = '';
      this.edit.area = '';
      this.ApiService.getareas({ city_id: e.value }).then((data) => {
        if (data.success == true) {
          this.areaList = data.data;
          this.arealoaderflag = false;
        } else {
          this.arealoaderflag = false;
          this.areaList = null;
        }
      });
    },
    createNewVoter() {
      this.submitted = true;
      this.errormsg = '';
      this.v$.add.$validate();
      var formData = new FormData();
      formData.append('ak10', this.add.title.value);
      formData.append('ak11', this.add.title.label);
      formData.append('ak7', this.add.firstname);
      if (this.add.middlename) {
        formData.append("ak8", this.add.middlename);
      }
      formData.append('ak9', this.add.lastname);
      var fullname = this.add.title.label + ' ' + this.add.firstname + ' ' + this.add.middlename + ' ' + this.add.lastname;
      formData.append('ak5', fullname);
      formData.append('ak24', this.add.primobile);
      if (this.add.email) {
        formData.append("ak23", this.add.email);
      }
      formData.append('ak18', moment(this.add.birthdate).format("YYYY-MM-DD"));
      if (this.add.bloodgroup) {
        formData.append("ak20", this.add.bloodgroup.value);
        formData.append("ak21", this.add.bloodgroup.label);
      }
      if (this.add.profession) {
        formData.append("ak66", this.add.profession.value);
        formData.append("ak67", this.add.profession.label);
      }
      formData.append('ak22', this.add.gender.value);
      formData.append('ak29', this.add.votertype);
      formData.append("ak62", this.add.loksabha.value);
      formData.append("ak63", this.add.loksabha.label);
      formData.append("ak60", this.add.vidhansabha.value);
      formData.append("ak61", this.add.vidhansabha.label);
      formData.append('ak51', this.add.fulladdress);
      formData.append('ak58', this.add.pincode);
      if (this.add.landmark) {
        formData.append('ak59', this.add.landmark);
      }
      if (this.add.tagname) {
                formData.append('tagname', JSON.stringify(this.add.tagname));
            }
      formData.append("ak54", this.add.city.value);
      formData.append("ak55", this.add.city.label);
      if (this.add.companyname) {
        formData.append("ak88", this.add.companyname.label);
        formData.append("ak145", this.add.companyname.value);
      }
      if(this.add.voterowner) {
        formData.append("ak102", this.add.voterowner.value);
        formData.append("ak104", this.add.voterowner.label);
      } else {
        formData.append("ak102", '');
        formData.append("ak104", '');
      }
      if (this.add.area) {
        formData.append("ak52", this.add.area.value);
        formData.append("ak53", this.add.area.label);
      }
      if (this.add.designation) {
        formData.append("ak83", this.add.designation.value);
        formData.append("ak84", this.add.designation.label);
      }
      if (this.add.votercreateimg) {
        formData.append("ak30", this.add.votercreateimg);
      }
      if (!this.v$.add.$error && !this.errorprocreateimage) {
        this.showloader = true;
        this.ApiService.createNewVoter(formData).then((data) => {
          if (data.status == 200) {
            this.showloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.showloader = false;
            this.add.primobile = '';
            this.add.title = '';
            this.add.firstname = '';
            this.add.middlename = '';
            this.add.lastname = '';
            this.add.email = '';
            this.add.votertype = 1;
            this.add.gender = '';
            this.add.birthdate = '';
            this.add.loksabha = '';
            this.add.vidhansabha = '';
            this.add.companyname = '';
            this.add.designation = '';
            this.isCompanyNameExistAdd = false;
      this.isDesignationExistAdd = false;
            this.add.votercreateimg = null;
            this.add.fulladdress = '';
            this.add.pincode = '';
            this.add.landmark = '';
            this.add.city = '';
            this.add.area = '';
            this.add.voterowner = '';
            this.add.bloodgroup = '';
            this.add.profession = '';
            this.add.tagname = null;
            this.voternotexiststatus = false;
            this.clearsearchvoterform();
            this.getclientdesignations();
      // this.getuserdesignation();
            setTimeout(() => {
              this.redirecttovoterlisting();
            }, 4000);
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
          }
        });
      } else {
        this.$error;
      }
    },
    redirecttovoterlisting() {
      this.$router.push("/voters/myvoters");
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    clearsearchvoterform() {
      this.add.title = '';
      this.add.firstname = '';
      this.add.middlename = '';
      this.add.lastname = '';
      this.add.primobile = '';
      this.add.email = '';
      this.add.gender = '';
      this.add.votertype = 1;
      this.add.birthdate = null;
      this.add.loksabha = '';
      this.add.vidhansabha = '';
      this.add.designation = '';
      this.add.companyname = '';
      this.isCompanyNameExistAdd = false;
      this.isDesignationExistAdd = false;
      this.add.votercreateimg = '';
      this.add.fulladdress = '';
      this.add.city = '';
      this.add.area = '';
      this.add.pincode = '';
      this.add.landmark = '';
      this.add.voterowner = '';
      this.add.tagname = null;
      this.add.bloodgroup = '';
      this.add.profession = '';
      this.edit.birthdate = null;
      this.edit.loksabha = '';
      this.edit.vidhansabha = '';
      this.edit.companyname = '';
      this.edit.designation = '';
      this.edit.importprofilefile = '';
      this.editRowId = '';
      this.edit.voterprofileimg = '';
      this.edit.title = '';
      this.edit.firstname = '';
      this.edit.middlename = '';
      this.edit.lastname = '';
      this.edit.email = '';
      this.edit.gender = '';
      this.edit.primobile = '';
      this.edit.countrycode = { value: 101, label: "+91" };
      this.edit.fulladdress = '';
      this.edit.city = '';
      this.edit.area = '';
      this.edit.pincode = '';
      this.edit.bloodgroup = '';
      this.edit.profession = '';
      this.edit.landmark = '';
      this.edit.tagname = null;
      this.countrycode = { value: 101, label: "+91" };
      this.showloader = false;
      this.submitted = false;
      this.errorprofileimage = '';
      this.vidhanloaderflag = false;
      this.cityloaderflag = false;
      this.arealoaderflag = false;
      this.createpreviewurl = null;
      this.searchmobile = '';
      this.searchbtnloader = false;
      this.voternotexiststatus = false;
      this.searchmobactivestatus = false;
      this.searchdatafoundstatus = false;
      this.editvoterbtnloader = false;
      this.errorprocreateimage = '';
      this.editvoterdetailstatus = false;
      this.showloadervotermodal = false;
      this.exitpreviewurl = null;
      this.searchMobileResult = [];
      this.voterownerList = [];
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    voterDetailEditModalOpen(e) {
      this.editvoterdetailstatus = true;
      this.showloadervotermodal = true;
      this.edit.gender = '';
      this.edit.birthdate = null;
      this.exitpreviewurl = null;
      this.edit.tagname = null;
      this.errorprofileimage = '',
      this.getclientdesignations();
      // this.getuserdesignation();
      this.isCompanyNameExist = false;
      this.isDesignationExist = false;
      this.editRowId = e.ak1;
      if(this.allowshowmobilevoterfk == 1) {
        this.edit.primobile = e.ak24;
      } else if(this.allowshowmobilevoterfk == 0) {
        this.edit.primobile = this.maskedNumber(e.ak24);
      }
      if (e.ak10 != null && e.ak10 != '') {
        this.edit.title = {
          label: e.ak11,
          value: e.ak10,
        };
      }
      this.edit.firstname = e.ak7;
      this.edit.middlename = e.ak8;
      this.edit.lastname = e.ak9;
      if(this.allowshowemailvoterfk == 1) {
        this.edit.email = e.ak23;
      } else if(this.allowshowemailvoterfk == 0) {
        if(e.ak23 != null && e.ak23 != "") {
          this.edit.email = this.maskedEmail(e.ak23);
        } else {
          this.edit.email = '';
        }
      }
      // this.edit.email = e.ak23;
      if (e.ak18 != null && e.ak18 != "") {
        this.edit.birthdate = new Date(e.ak18);
      }
      if (e.ak22 == 1) {
        this.edit.gender = {
          label: "Male",
          value: e.ak22,
        }
      } else if (e.ak22 == 2) {
        this.edit.gender = {
          label: "Female",
          value: e.ak22,
        }
      } else if (e.ak22 == 3) {
        this.edit.gender = {
          label: "Other",
          value: e.ak22,
        }
      }
      if (e.ak62 != null && e.ak62 != '') {
        this.edit.loksabha = {
          label: e.ak63,
          value: e.ak62,
        };
      }
      if (e.ak60 != null && e.ak60 != '') {
        this.edit.vidhansabha = {
          label: e.ak61,
          value: e.ak60,
        };
      }
      if (e.ak83 != null && e.ak83 != '') {
        this.edit.designation = {
          label: e.ak84,
          value: e.ak83,
        };
      }
      if (e.ak90 != null && e.ak90 != "") {
        this.edit.importprofilefile = "https://storage.googleapis.com/" + this.voterstorageimgpath + "/voterphoto/" + e.ak90;
      } else {
        this.edit.importprofilefile = null;
      }
      if (e.ak88 != null && e.ak88 != '') {
        this.edit.companyname = {
          label: e.ak88,
          value: e.ak145,
        };
      }
      this.edit.fulladdress = e.ak51;
      this.edit.pincode = e.ak58;
      this.edit.landmark = e.ak59;
      if (e.ak20 != null && e.ak20 != '') {
        this.edit.bloodgroup = {
          label: e.ak21,
          value: e.ak20,
        };
      }
      if (e.ak122 != null && e.ak122 != '') {
        this.edit.tagname = {
          label: e.ak122,
          value: e.ak121,
        };
      }
      if (e.ak66 != null && e.ak66 != '') {
        this.edit.profession = {
          label: e.ak67,
          value: e.ak66,
        };
      }
      if (e.ak54 != null && e.ak54 != '') {
        this.edit.city = {
          label: e.ak55,
          value: e.ak54,
        };
      }
      if (e.ak52 != null && e.ak52 != '') {
        this.edit.area = {
          label: e.ak53,
          value: e.ak52,
        };
      }
      this.showloadervotermodal = false;
    },
    voterDetailEditModalClose() {
      this.editvoterdetailstatus = false;
    },
    updateVoterDetailbtn() {
      this.v$.edit.$validate();
      var formData = new FormData();
      formData.append("ak1", this.editRowId);
      formData.append("ak24", this.edit.primobile);
      formData.append("ak10", this.edit.title.value);
      formData.append("ak11", this.edit.title.label);
      formData.append("ak7", this.edit.firstname);
      if (this.edit.middlename) {
        formData.append("ak8", this.edit.middlename);
      }
      formData.append("ak9", this.edit.lastname);
      var fullname = this.edit.title.label + ' ' + this.edit.firstname + ' ' + this.edit.middlename + ' ' + this.edit.lastname;
      formData.append('ak5', fullname);
      if (this.edit.email) {
        formData.append("ak23", this.edit.email);
      }
      if (this.edit.tagname) {
        formData.append('tagname', JSON.stringify(this.edit.tagname));
      }
      formData.append('ak18', moment(this.edit.birthdate).format("YYYY-MM-DD"));
      if (this.edit.bloodgroup) {
        formData.append("ak20", this.edit.bloodgroup.value);
        formData.append("ak21", this.edit.bloodgroup.label);
      }
      if (this.edit.profession) {
        formData.append("ak66", this.edit.profession.value);
        formData.append("ak67", this.edit.profession.label);
      }
      formData.append("ak22", this.edit.gender.value);
      formData.append("ak62", this.edit.loksabha.value);
      formData.append("ak63", this.edit.loksabha.label);
      formData.append("ak60", this.edit.vidhansabha.value);
      formData.append("ak61", this.edit.vidhansabha.label);
      formData.append('ak51', this.edit.fulladdress);
      formData.append('ak58', this.edit.pincode);
      if (this.edit.landmark) {
        formData.append("ak59", this.edit.landmark);
      }
      formData.append("ak54", this.edit.city.value);
      formData.append("ak55", this.edit.city.label);
      formData.append("ak52", this.edit.area.value);
      formData.append("ak53", this.edit.area.label);
      if (this.edit.companyname) {
                formData.append("ak145", this.edit.companyname.value);
                formData.append("ak88", this.edit.companyname.label);
            }
      if (this.edit.designation) {
        formData.append("ak83", this.edit.designation.value);
        formData.append("ak84", this.edit.designation.label);
      }
      if (this.edit.voterprofileimg) {
        formData.append("voterprofileimg", this.edit.voterprofileimg);
      }
      if (!this.v$.edit.$error && !this.errorprofileimage) {
        this.editvoterbtnloader = true;
        this.ApiService.updatevotersdetails(formData).then((data) => {
          if (data.status == 200) {
            this.editvoterbtnloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.editvoterdetailstatus = false;
            this.clearsearchvoterform();
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.editvoterbtnloader = false;
            this.editvoterdetailstatus = true;
          }
        });
      } else {
        this.$error;
      }
    },
    addNewExitMobVoterModalOpen() {
      this.voternotexiststatus = true;
      this.searchdatafoundstatus = false;
    },
    getbloodgroups(e) {
      this.ApiService.getbloodgroups(e).then((data) => {
        if (data.status == 200) {
          this.bloodgroupList = data.data;
        } else {
          this.bloodgroupList = null;
        }
      });
    },
    gettags(e) {
      this.ApiService.gettags(e).then((data) => {
        if (data.status == 200) {
          if(this.allowtagadditionaccess == 1) {
            this.taggroupList = data.data;
          } else {
            this.taggroupList = null;
          }
        } else {
          this.taggroupList = null;
        }
      });
    },
    createVoterEditDesignation(event) {
      if (event && event.value) {
        if (Number.isInteger(event.value)) {
          this.edit.designation = { value: event.value, label: event.label };
        } else {
          this.edit.designation = { value: 0, label: event.label };
        }
      }
    },
    createVoterAddDesignation(event) {
      if (event && event.value) {
        if (Number.isInteger(event.value)) {
          this.add.designation = { value: event.value, label: event.label };
        } else {
          this.add.designation = { value: 0, label: event.label };
        }
      }
    },
    createVoterAddCompanyName(event) {
      if (event && event.value) {
        if (Number.isInteger(event.value)) {
          this.add.companyname = { value: event.value, label: event.label };
        } else {
          this.add.companyname = { value: 0, label: event.label };
        }
      }
    },
    onlypincodeEditFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var editpincodeInput = document.getElementById("editpincodeInput");
      if ((editpincodeInput.value == '' || editpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    onlymobileEditFormat($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }

        var editmobileInput = document.getElementById("editmobileInput");
        if ((editmobileInput.value == '' || editmobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
            $event.preventDefault();
        }
    },
    maskedEmail(getvalue) {
        const [name, domain] = getvalue.split('@');
        const [firstDomain, sublastdomain, lastdomain] = domain.split('.');
        if(name.length == 1 && !lastdomain) {
            return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}`;
        } else if(name.length == 1 && lastdomain) {
            return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}.${lastdomain}`;
        } else {
            return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
        }
    },
    maskedNumber(getvalue) {
        const maskedDigits = 'X'.repeat(getvalue.length - 4);
        const FirstThreeDigits = getvalue.slice(0, 2);
        const lastThreeDigits = getvalue.slice(-2);
        return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
  },
};
</script>
<style scoped>
.voter-detail-box-outer {
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  border-top: 0;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 522px;
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.voter-already-user-status {
  padding: 8px 16px;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  margin-bottom: 15px;
  color: #63957d;
  font-weight: 700;
  font-family: "AcuminPro-SemiBold";
}
</style>